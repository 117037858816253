import React, { useState } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo"
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import homebanner from '../../images/landing/bg3.png';
import Play from '../../images/icons/Play.png';
import bgcircle from '../../images/landing/bgcircle.svg';
import transparency from '../../images/landing/transparency.svg';
import quality from '../../images/landing/quality.svg';
import unbiaseddata from '../../images/landing/unbiased-data.svg';
import satisfaction from '../../images/landing/satisfaction.svg';
import money from '../../images/landing/money.svg';

import Userbackground from '../../images/landing/bg2.jpg';
import useFirestore from '../../modules/flamelink-module';
import _ from 'lodash';
import './dataannotation_styles.scss'
import { UNBIASED_VIDEO, CONTACT_US } from '../../config/constants';
import ContactModal from '../../components/ContactModal';


export default function DataAnnotation() {
    const dataannotation = useFirestore('fl_content', 'dataannotation');
    const innovations = useFirestore('fl_content', 'innovation');
    const [activeType, setActiveType] = useState(0);
    const [activeSubCategory, setActiveSubCategory] = useState(0);
    const [activeInnavative, setActiveInnavative] = useState(0);
    const [open, setOpen] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);

    const onClickAnnotation = async (index) => {
        setActiveType(index);
        setActiveSubCategory(0);
    }

    const onHandleServiceImage = async (index) => {
        setActiveSubCategory(index);
    }

    const onHandleDataAnnotation = async (data, index) => {
        setActiveInnavative(index);
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenVideo = () => {
        setOpenVideo(true);
    };

    const handleCloseVideo = () => {
        setOpenVideo(false);
    };


    return (
        <Layout>
            <SEO title="Data Annotation" description="" />
            <ContactModal open={open} handleClose={handleClose} />
            <ContactModal open={openVideo} handleClose={handleCloseVideo} source={UNBIASED_VIDEO} />
            <div className="dataAnnotation">
                <section className="main-banner">
                    <img src={homebanner} alt="Home Banner" className="homeBannerImg" />
                    <Container>
                        <div className="annotation-banner">
                            <Grid container direction="column">
                                <Grid item xs={12} sm={12} >
                                    <h2>{!_.isEmpty(dataannotation) && dataannotation[0].title}</h2>
                                </Grid>
                                <Grid item xs={12} sm={12} className="buttonss">
                                    <Grid container >
                                        <Grid item>
                                            <Button onClick={handleOpenVideo} target="_blank" className="btnActive"><img src={Play} alt="Play" width="20" /> What's Unbiased?</Button>
                                        </Grid>
                                        <Grid item className="btContact">
                                            <Button onClick={handleOpen} target="_blank" className="btnInActive">Contacts Us</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <ul>
                                    {
                                        !_.isEmpty(dataannotation) && dataannotation[0].hasOwnProperty('annotationtype') && dataannotation[0].annotationtype.map((val, index) => {
                                            return (
                                                <li key={index}><a href tabindex="0" className={activeType === index ? "active" : ''} onClick={() => onClickAnnotation(index)}>{val.type}</a></li>
                                            )
                                        })
                                    }
                                </ul>
                            </Grid>
                        </div>
                    </Container>
                </section>

                <section className="data-annotation">
                    <Container>
                        <h6>SERVICES</h6>
                        <h2>DATA ANNOTATION </h2>
                        <h2>KEEPING HUMANS IN THE LOOP</h2>
                        <Grid container className="mainwrap">
                            <Grid item xs={12} sm={12} md={4}>
                                <div className="container">
                                    <div className="dataAnnotate">
                                        <div className="serviceTxt">
                                            {!_.isEmpty(dataannotation) && dataannotation[0].hasOwnProperty('annotationtype') && dataannotation[0].annotationtype[activeType].subcategory.map((val, index) => {
                                                return (
                                                    <>
                                                        {activeSubCategory === index ?
                                                            <div key={index} className="sBox">
                                                                <div className="ImgCont">
                                                                    {!_.isEmpty(dataannotation) && dataannotation[0].hasOwnProperty('annotationtype') && dataannotation[0].annotationtype[activeType].subcategory.length !== index + 1 &&
                                                                        <span className="line"></span>
                                                                    }
                                                                    <a href tabindex="0" className={index === 0 ? "c1" : index === 1 ? "c2" : index === 2 ? "c3" : index === 3 ? "c4" : index === 4 ? "c2" : index === 5 ? "c3" : "c1"}>
                                                                    </a>
                                                                </div>
                                                                <div className="sBoxDta">
                                                                    <h5>{val.subtitle}</h5>
                                                                    <p>{val.description}</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="sBox" style={{ cursor: 'pointer' }}>
                                                                <div className="ImgCont">
                                                                    {!_.isEmpty(dataannotation) && dataannotation[0].hasOwnProperty('annotationtype') && dataannotation[0].annotationtype[activeType].subcategory.length !== index + 1 &&
                                                                        <span className="line"></span>
                                                                    }
                                                                    <a href tabindex="0" className={index === 0 ? "c1" : index === 1 ? "c2" : index === 2 ? "c3" : index === 3 ? "c4" : index === 4 ? "c2" : index === 5 ? "c3" : "c1"} onClick={() => onHandleServiceImage(index)}>
                                                                    </a>
                                                                </div>
                                                                <div className="sBoxDta" onClick={() => onHandleServiceImage(index)}>
                                                                    <h5>{val.subtitle}</h5>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} className="collection-cont">
                                <div className="dtImg">
                                    {!_.isEmpty(dataannotation) && dataannotation[0].hasOwnProperty('annotationtype') &&
                                        <img src={dataannotation[0].annotationtype[activeType].subcategory[activeSubCategory].imagelink} alt="Bounding Box" />
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </section>

                <section className="useCaseCont">
                    <Container className="container">
                        <h2>{!_.isEmpty(dataannotation) && dataannotation[0].hasOwnProperty('annotationtype') && dataannotation[0].annotationtype[activeType].usecasetitle}</h2>
                        <div className="row">
                            {!_.isEmpty(dataannotation) && dataannotation[0].hasOwnProperty('annotationtype') && dataannotation[0].annotationtype[activeType].usecase.map((val, index) => {
                                return (
                                    <>
                                        <div key={index} className="col-xs-12 col-sm-6 col-md-4">
                                            <div className="box">
                                                <img src={val.location} alt="Thumbnail" />
                                                <h4>{val.title}</h4>
                                                <p>{val.summary}</p>
                                                <a href tabindex="0">LEARN MORE</a>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </Container>
                </section>

                <div className="innovationCont">
                    <Container>
                        <h2>{!_.isEmpty(innovations) && innovations[0].heading}</h2>
                        <div className="innovBox">
                            <Grid container>
                                <Grid item xs={12} sm={5} md={5}>
                                    <div className="innovListBox">
                                        <ul>
                                            {!_.isEmpty(innovations) && innovations[0].hasOwnProperty('innovationpart') && innovations[0].innovationpart.map((val, index) => {
                                                return (
                                                    <>
                                                        {
                                                            activeInnavative === index ?
                                                                <li className="active">
                                                                    <span><img src={val.iconLocation} width="18" alt="Icon" /></span>
                                                                    <div className="glist">
                                                                        <h5>{val.subheading}</h5>
                                                                        <p>{val.description}</p>
                                                                    </div>
                                                                </li>
                                                                :
                                                                <li onClick={() => onHandleDataAnnotation('projectsRange', index)} style={{ cursor: 'pointer' }}>
                                                                    <span><img src={val.iconLocation} width="16" alt="Icon" /></span>
                                                                    <div className="glist">
                                                                        <h5>{val.subheading}</h5>
                                                                    </div>
                                                                </li>
                                                        }
                                                    </>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={7} md={6}>
                                    <div className="innovImgBox">
                                        {!_.isEmpty(innovations) && innovations[0].hasOwnProperty('innovationpart') &&
                                            <img src={innovations[0].innovationpart[activeInnavative].imageLocation} alt="Innovation" />
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>
                <section className="whyChoose">
                    <img src={bgcircle} alt="Background" className="bgCircle" />
                    <section container className="why-choose-cont">
                        <h2>Why choose unbiased?</h2>
                        <p>Unbiased is innovating to solve many of the current problems in the Artifical Intelligence & Machine Learning industry such as Transparency, Bias and Quality of Training Data. Unbiased Data Marketplace platform will act as a gateway to Data Annotations, knowledge sharing, collaborations & futuristic innovation.</p>

                        <div className="circleContLarge">
                            <div className="cCont">
                                <div className="circle">
                                    <img src={transparency} alt="Transparency" width="36" />
                                </div>
                                <h6>TRANSPARENCY</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={quality} alt="Quality Assurance" width="36" />
                                </div>
                                <h6>QUALITY ASSURANCE</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={unbiaseddata} alt="Unbiased Data" width="34" />
                                </div>
                                <h6>UNBIASED DATA</h6>
                            </div>
                        </div>

                        <div className="circleContSmall">
                            <div className="cCont">
                                <div className="circle">
                                    <img src={satisfaction} alt="Worker Satisfaction" width="36" />
                                </div>
                                <h6>WORKER SATISFACTION</h6>
                            </div>
                            <div className="cCont">
                                <div className="circle">
                                    <img src={money} alt="Spam Free Accounts" width="36" />
                                </div>
                                <h6>SPAM FREE ACCOUNTS</h6>
                            </div>
                        </div>
                        <Grid container justify="center" className="btn-cont btn-conts">
                            <Button onClick={handleOpenVideo} target="_blank" className="btn-solid"><img src={Play} alt="play" width="20" /> What’s unbiased?</Button>
                            <Button className="btn-bordered">LEARN MORE</Button>
                        </Grid>
                    </section>
                </section>

                <section className="happy-users">
                    <img src={Userbackground} alt="Background" className="happy-user-bg" />
                    <Grid container direction="column" alignItems="center" justify="center" className="formCont">
                        <h2>JOIN THE LIST OF OUR HAPPY USERS</h2>
                        <Button onClick={handleOpenVideo} target="_blank">
                            <img src={Play} alt="play" width="20" />
                            What’s unbiased?
                        </Button>
                    </Grid>
                </section>

            </div>
        </Layout>
    );
}
